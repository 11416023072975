<template>
  <div>
    <v-row class="mt-5">
      <v-col cols="6" md="4">
        <v-autocomplete
          v-model="filters.year"
          :items="yearOptions"
          class="c-input-small input-disabled-bold"
          :label="$t('labels.year')"
          :placeholder="$t('labels.year')"
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4">
        <v-autocomplete
          v-model="filters.month"
          :items="monthOptions"
          class="c-input-small input-disabled-bold"
          :label="$t('labels.month')"
          :placeholder="$t('labels.month')"
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn
          color="success"
          block
          style="height: 40px"
          :disabled="disabledBtn"
          @click="downloadExcel"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t("labels.excel") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "S1Book",
  components: {},
  props: {},
  data: () => ({
    filters: {
      id_customer_tax_code: null,
      year: null,
      month: null,
    },
    taxCodeOptions: [],
  }),
  computed: {
    disabledBtn() {
      return !this.filters.year || !this.filters.month;
    },
    yearOptions() {
      const options = [];
      const currentYear = moment().year();
      for (let i = 2024; i <= currentYear; i++) {
        options.push({
          text: `Năm ${i}`,
          value: i,
        });
      }
      return options;
    },
    monthOptions() {
      const options = [];
      for (let i = 1; i <= 12; i++) {
        options.push({
          text: `Tháng ${i}`,
          value: i,
        });
      }
      return options;
    },
  },
  watch: {},
  mounted() {
    const currentYear = moment().year();
    const currentMonth = moment().month();
    this.filters = { ...this.filters, year: currentYear, month: currentMonth };
  },
  methods: {
    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = `S1_${this.filters.year}_${this.filters.month}.xlsx`;

      try {
        await this.downloadExcelFile(
          "/invoice-export-s1",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
